import React from 'react'

import { Box, Container, H2, H1, P } from '@allied-solutions/affinity'
import { FullScreenLayout, NoRefIdNav } from '../components'

import illustration from './illustration-500.svg'

const FiveHundred = () => {
  return (
    <FullScreenLayout nav={<NoRefIdNav noHelpButton />}>
      <img
        src={illustration}
        role="presentation"
        alt=""
        css={`
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100vw;
          z-index: -1;
        `}
      />
      <Container px={8} maxWidth="550px" role="main">
        <H1
          className="h4"
          typeStyle="h4"
          textAlign="center"
          fontWeight="900"
          mb="5"
        >
          We are sorry...
        </H1>
        <H2
          typeStyle="displayMedium"
          textAlign="center"
          fontWeight="900"
          mb="8"
        >
          Our systems are down and we can’t access your records.
        </H2>
        <Box width="30" height="2px" bg="gray1000" mb="8" mx="auto" />
        <P
          mb={0}
          className="55"
          typeStyle="h5"
          textAlign="center"
          fontWeight="500"
          maxWidth="370px"
          mx="auto"
        >
          Please return later to complete your work.
        </P>
      </Container>
    </FullScreenLayout>
  )
}

export default FiveHundred
